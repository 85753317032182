import { createContext, useContext, useState, useCallback } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = useCallback((username, password) => {
    const correctUsername = "rEvolutionName";
    const correctPassword = "rEvolutioN123";

    if (username === correctUsername && password === correctPassword) {
      setUser({ username });
      return { success: true };
    }
    return { 
      success: false, 
      message: "The username or password are invalid!" 
    };
  }, []);

  const logout = useCallback(() => {
    setUser(null);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};