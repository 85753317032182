import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../auth-service/AuthService";

export function ProtectionPage() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const result = login(username, password);

    if (result.success) {
      setErrorMessage("");
      navigate("/checkout");
    } else {
      setErrorMessage(result.message);
    }
  };

  return (
    <div className="protection-container">
      <form onSubmit={handleLoginSubmit} id="protectionForm">
        <div className="protection-page-title">
          <FontAwesomeIcon icon={faUser} />
          <p className="protection-form-title">Log in</p>
        </div>
        <div className="protection-page-section">
          <label>Username</label>
          <input
            type="text"
            name="username"
            placeholder="Please insert your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="protection-page-section">
          <label>Password</label>
          <input
            type="password"
            name="password"
            placeholder="Please insert your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <input
          type="submit"
          value="Log in"
          className="btn btn-success protection-btn"
          disabled={!username || !password}
        />
        {errorMessage && (
          <span className="login-error-message">{errorMessage}</span>
        )}
      </form>
    </div>
  );
}